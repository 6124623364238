.serviciosComponent{
    padding-top: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: min-content;
    background-image: url('./img/pattern2.jpg');
    background-repeat: initial;
    background-size: contain;
    padding-bottom: 40px;
    padding: 40px;
}

.serviciosComponent aside{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    height: 700px;
    
}

.serviciosContent{
    width: 70%;
    height: min-content;
}

.serviciosContent p{
    text-align: justify;
}


.serviciosComponent .selectionTab{
    
    width: 80%;
    height: 50%;
    
}

.godinezMadrigalLogo{
    background-position: center;
    background-image: url('./img/logoGM.png');
    width: 100%;
    height: 30%;
    background-repeat: no-repeat;
    background-size: contain;
}

.serviciosSideElement{
    width: 100%;
    height: 20%;
    background-color: #00367d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    border: 1px white solid;
    transition: ease 0.5s;
    border-radius: 20px;
    font-size: 15px;
}

.serviciosSideElement:hover{
    background-color: black;
    transition: ease 0.5s;
}

.selectedSideElement{
    width: 100%;
    height: 20%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 15px;
    border: 1px white solid;
    transition: ease 0.5s;
    border-radius: 20px;

}

.serviciosContent{
    border-radius: 20px;
    border: 5px  solid #00367d;
    background-color: #ffff;
    padding: 40px;
}

.serviciosContent p{
    font-size: x-large;
    line-height: 30px;
}

.serviciosContent h3{
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
}



.serviciosContent h1{
    line-height: 40px;
}

.serviciosContent h1{
    line-height: 40px;
}


.serviciosContent li{
    font-size: 30px;
    margin-top: 20px;
    margin-left: 40px;
    line-height: 35px;
}

.serviciosContent div{
    height: fit-content;
}



@media only screen and (max-width: 991px) {
    .serviciosComponent{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: fit-content;
    }

    .serviciosContent div{
        width: 100%;
        height: fit-content;
    }
    
    .serviciosContables{
        height: 2000px;
        width: 100%;
    }
    .serviciosComponent .selectionTab{
        display: flex;

        width: 100%;
        height: 50%;
        background-color: white;
    }
    .serviciosSideElement{
        padding: 10px;
        width: 25%;
        height: 150px;
        font-size: 12px;
        background-color: #00367d;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        border: 1px white solid;
        transition: ease 0.5s;
        border-radius: 20px;
    }
    .selectedSideElement{
        padding: 10px;
        font-size: 12px;

        width: 25%;
        height: 150px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        border: 1px white solid;
        transition: ease 0.5s;
    
    }

    
    .serviciosComponent aside{
        word-break: break-all;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 200px;
        margin-bottom: 30px;
    }
    .godinezMadrigalLogo{
        display: none;
    }

    .serviciosComponent {
        width: 100%;
        height: min-content;
        margin-bottom: 20px;
    }

    .serviciosContent{
        width: 90%;
        height: min-content;
    }
}


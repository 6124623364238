.projectContainer{
    width: 100%;
    height: fit-content;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #505050;
}

.ubicamosMapa{
    padding: 30px;
    width: 100%;
    height: 100%;
}

.ubicamosMapa h1{
    color: white;
}



.containerUbicamos {
    width: 100%;
    height: fit-content;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactoColor {
    background-color: #00367d;
    width: 100%;
    height: 100%;
}

.containerUbicamos h2 {
    text-align: center;
}

.correoTexto {
    text-align: center;
    margin: 20px;
    font-size: 35px;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .correoTexto {
        font-size: 15px;
    }
    .containerUbicamos{
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    .correoTexto {
        font-size: 20px;
    }
    .containerUbicamos{
        flex-direction: column;
    }
    
}

@media only screen and (min-width: 991px) {
    .correoTexto {
        font-size: 30px;
        line-height: 30px;
    }
    .containerUbicamos{
        flex-direction: column;
    }
}

@media only screen and (min-width: 1200px) {
    .correoTexto {
        font-size: 35px;
        line-height: 30px;
    }

    .containerUbicamos{
        flex-direction: row;
    }
}

.cajaNumeros {
    width: 100%;
    height: min-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #505050;
    padding-top: 6
    0px;
    padding-bottom: 60px;
    
}

.iconosTitle{
    color: white;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconosTitle h2{
    color: white;
    font-size: 15px;
}


.titleContactanos h1{
    color: white;
}

.elementosContactanos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:#505050;
}


.seccionesInfoContactanos {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    height: 100%;
}

.seccionesInfoContactanos p{
    font-size: 12px;
    width: 60%;
    text-align: start;
}

.seccionesInfoContactanos svg{
    width: 25%;
    height: auto;
    fill: #FFE545;
    margin-left: 25px;
}

.titleContactanos{
    margin-bottom: 40px;
}

.emailText{
    word-wrap: break-word;
    color: white;
    font-size: 16px;
    width: 60%;
    text-align: center;
}

.emailText a{
    word-wrap: break-word;
    color: white;
    font-size: 18px;
    width: 60%;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .titleContactanos{
        margin-top: 30px;
    }

    .seccionesInfoContactanos{
        width: 100%;
        margin-top: 30px;
    }
    .seccionesInfoContactanos p{
        font-size: 15px;
        padding: 20px;
        text-align: center;
    }
    .elementosContactanos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .cajaNumeros {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        
    }
}

@media only screen and (min-width: 600px) {
    
    .seccionesInfoContactanos p{
        font-size: 15px;
        padding: 20px;
        text-align: center;
    }
    
    .seccionesInfoContactanos p{
        font-size: 15px;
        text-align: center;
        overflow-wrap: break-word; 
        
    }
}

@media only screen and (min-width: 991px) {
    .seccionesInfoContactanos svg{
        width: 25%;
        height: auto;
        fill: #FFE545;
    }
}

@media only screen and (min-width: 1200px) {
    .seccionesInfoContactanos p{
        font-size: 18px;
        text-align: center;
    }
}

h1 {
    text-align: center;
    color: #00367d;
    font-weight: 700;
}

li {
    list-style-type: disc;
}

h2 {
    font-weight: 700;
}

div {
    color: black;
}

.gridNotes {
    margin-top: 30px;
}

.gridNotes {
    width: 100%;
    display: grid;
    height: 1200px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.item-a {
    background: #00367d;
    color: white;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 30px;
    margin: 5px;
    font-size: medium;
    border-radius: 10px;
    transition: background 0.5s ease;
}
.item-a:hover {
    background: url("./img/ServiciosContables.jpg");
    transition: background 0.5s ease;
    background-size: cover;
}

.item-a div{
    width: 100%;
    height: 100%;
    padding: 20px;
    color: white;
}

.item-b {
    background: black;
    color: white;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
    padding: 30px;
    margin: 5px;
    font-size: medium;
    border-radius: 10px;
    transition: ease 0.5s;
    transition: background 0.5s ease;

}
.item-b:hover {
    background: url("./img/devolucionSaldos.jpg");
    transition: background 0.5s ease;
    background-size: cover;
}

.item-b p{
    text-align: justify;
}

.item-c {
    background: #ffe545;
    color: black;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    padding: 30px;
    margin: 5px;
    font-size: medium;
    border-radius: 10px;
    transition: background 0.5s ease;

}

.item-c:hover {
    background: url("./img/asesoria.jpg");
    transition: background 0.5s ease;
    background-size: cover;
    color: white;
    
}

.item-c p{
    text-align: justify;
}
.item-d {
    background: #00367d;
    color: white;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 6;
    padding: 30px;
    margin: 5px;
    font-size: medium;
    border-radius: 10px;
    transition: background 0.5s ease;

}
.item-d:hover {
    background: url("./img/gobierno.jpg");
    transition: background 0.5s ease;
    background-size: cover;

}

.item-d p{
    text-align: justify;
}

.item-e {
    background: black;
    color: white;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 7;
    padding: 30px;
    margin: 5px;
    font-size: medium;
    border-radius: 10px;
    transition: background 0.5s ease;

}

.item-e:hover {
    background: url("./img/auditorias.jpg");
    transition: background 0.5s ease;
    background-size: cover;

}

.item-e p{
    text-align: justify;
}

@media only screen and (max-width: 991px) {
    .gridNotes {
        width: 100%;
        display: grid;
        height: 1900px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .item-a {
        background-color: #00367d;
        color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        padding: 30px;
        margin: 5px;
        font-size: medium;
        border-radius: 10px;
    }

    .item-b {
        background-color: black;
        color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 5;
        padding: 30px;
        margin: 5px;
        font-size: medium;
        border-radius: 10px;
    }
    .item-c {
        background-color: rgb(99, 99, 99);
        color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 5;
        grid-row-end: 7;
        padding: 30px;
        margin: 5px;
        font-size: medium;
        border-radius: 10px;
    }
    .item-d {
        background-color: #00367d;
        color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 7;
        grid-row-end: 9;
        padding: 30px;
        margin: 5px;
        font-size: medium;
        border-radius: 10px;
    }

    .item-e {
        background-color: black;
        color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 9;
        grid-row-end: 11;
        padding: 30px;
        margin: 5px;
        font-size: medium;
        border-radius: 10px;
    }
}

.contenedorTestimonios{
    background-color: #00367D;
    width: 100%;
    height: 800px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
}

.tituloTestimonios{
    margin-top: 25px;
    text-align: center;
    color: white;
    font-size: 65px;
}


.testimonio-a{
    background-color: aliceblue;
    display: flex;
    width: 100%;
    height: 450px;
}

.fondo1{
    background-image: url('./img/autracen.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.fondo2{
    background-image: url('./img/juarezlincoln.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.fondo3{
    background-image: url('./img/AMC.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.fondo4{
    background-image: url('./img/lasalle.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.fondo5{
    background-image: url('./img/segrak.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.fondo6{
    background-image: url('./img/todokleen.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.image{
    width: 30%;
    height: 100%;
    background-color: #00367D;
}

.textoTestimonio{
    width: 70%;
    height: 100%;
    padding: 40px;
    text-align: justify;
}

.fraseTestimonio{
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
    text-align: justify;
}

.fraseTestimonio1{
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    text-align: justify;
}
.persona1{
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

.fraseTestimonio2{
    font-size: 60px;
    font-weight: 600;
    line-height: 65px;
    text-align: left;
    
}
.persona2{
    font-size: 20px;
    line-height: 30px;
}

.fraseTestimonio3{
    font-size: 38px;
    font-weight: 600;
    line-height: 40px;
}
.persona3{
    font-size: 20px;
    line-height: 30px;
}

.fraseTestimonio4{
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
}
.persona4{
    font-size: 20px;
    line-height: 30px;
}

.swiper-margin{
    margin-top: 50px;
}


@media only screen and (max-width: 991px) {
    .contenedorTestimonios{
        height: 1000px;
        padding-left: 5%;
        padding-right: 5%;
        
    }

    .fraseTestimonio1{
        font-size: 35px;
        font-weight: 600;
        line-height: 35px;
    }
    .persona1{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio2{
        font-size: 45px;
        font-weight: 600;
        line-height: 50px;
    }
    .persona2{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio3{
        font-size: 30px;
        font-weight: 600;
        line-height: 35px;
    }
    .persona3{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio4{
        font-size: 40px;
        font-weight: 600;
        line-height: 45px;
    }
    .persona4{
        font-size: 20px;
        line-height: 30px;
    }

    .fondo1{
        background-position: center;
    }
    .fondo2{
        background-position: center;
    }
    .fondo3{
        background-position: center;
    }
    .fondo4{
        background-position: center;
    }
    .fondo5{
        background-position: center;
    }
    .fondo6{
        background-position: center;
    }

    .image{
        width: 50%;
        height: 100%;
        background-color: #00367D;
    }

    .textoTestimonio{
        width: 50%;
        height: 100%;
        padding: 40px;
    }

    .fraseTestimonio{
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
    }
    .testimonio-a{
        background-color: aliceblue;
        display: flex;
        width: 100%;
        height: 750px;
    }
}

@media only screen and (max-width: 700px) {
    .contenedorTestimonios{
        height: 1000px;
        padding-left: 5%;
        padding-right: 5%;
        
    }

    .fraseTestimonio1{
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }
    .persona1{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio2{
        font-size: 60px;
        font-weight: 600;
        line-height: 65px;
    }
    .persona2{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio3{
        font-size: 38px;
        font-weight: 600;
        line-height: 40px;
    }
    .persona3{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio4{
        font-size: 50px;
        font-weight: 600;
        line-height: 50px;
    }
    .persona4{
        font-size: 20px;
        line-height: 30px;
    }

    .image{
        width: 100%;
        height: 100%;
        background-color: #00367D;
    }

    .textoTestimonio{
        width: 100%;
        height: 100%;
        padding: 40px;
    }

    .fraseTestimonio{
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
    }
    .testimonio-a{
        background-color: aliceblue;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 750px;
    }
}

@media only screen and (max-width: 500px) {
    .contenedorTestimonios{
        height: 1000px;
        padding-left: 5%;
        padding-right: 5%;
        
    }

    .fraseTestimonio{
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
    }

    .fraseTestimonio1{
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }
    .persona1{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio2{
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
    }
    .persona2{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio3{
        font-size: 28px;
        font-weight: 600;
        line-height: 30px;
    }
    .persona3{
        font-size: 20px;
        line-height: 30px;
    }
    
    .fraseTestimonio4{
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
    }
    .persona4{
        font-size: 20px;
        line-height: 30px;
    }

    .image{
        width: 100%;
        height: 100%;
        background-color: #00367D;
    }

    .textoTestimonio{
        width: 100%;
        height: 100%;
        padding: 40px;
    }

    .fraseTestimonio{
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
    }
    .testimonio-a{
        background-color: aliceblue;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 750px;
    }

    .tituloTestimonios{
        
        font-size: 50px;
    }
}


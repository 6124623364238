.gm-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}
.loaderElement {
    width: 50%;
    height: 50%;
    background-image: url("./img/LOGO_GM.gif");
    background-size: contain;
    background-repeat: no-repeat;
}

.gm-loader2 {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}

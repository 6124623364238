svg {
    margin-right: 20px;
}

.liHover{
    cursor: pointer;
}

.footerInfoFlex{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.textFooter2{
    color: white;
    margin-left: 5px;
    line-height: 20px;
}

.flexFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.imageInfoFooter{
    width: 20px;
    height: 20px;
}

.forjadores {
    padding: 0;
    width: 70%;
    font-size: 10px;
    line-height: 10px;
    list-style-position: outside;
}



.image1footer::before {
    height: 10px;
    width: 10px;
}

.footerNav {
    margin-top: 20px;
}

.listfooter {
    padding-right: 70px;
    margin-top: 20px;
    padding-left: 50px;
    width: 70%;
}

.listfooter li{
    display: flex;
}

.circle-icon {
    width: 50px;
}

.liFooter {
    display: flex;
}

.flexLink {
    margin-top: 20px;
    width: 50%;
}

.button-margin {
    margin-top: 20%;
}

.button-margin button:hover {
    background-color: #00367d;
}
.nav-bar {
    width: 100%;
}

.logoGodinezMadrigal {
    width: 200px;
    margin-top: 10px;
    padding: 0;
}

.colorHeader {
    background-color: #00367d;
}

.contactanosButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffe545;
    width: 200px;
    height: 50px;
    border-radius: 20px;
    font-weight: 700;
    color: black;
    position: fixed !important;
    right: 50px;
    bottom: 75px;
    z-index: 1000;
}

.contactanosButton svg {
    margin-left: 10px;
}
.textContactanos {
    color: #fff;
}
.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 400px;
    background-color: #2e363a;
}
.flexCopyright {
    display: flex;
    justify-content: center;
}

.copyrightCenter {
    text-align: center;
}

.informationWidth {
    width: 70%;
}

.footerContainer a img {
    margin-right: 50px;
    max-height: 80%;
    width: auto;
}

.contactFooter {
    display: flex;
    align-items: center;
    width: 65%;
    height: 200px;
}

.textFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.textFooter a {
    color: white;
}

.textFooter li {
    color: white;
}

.linksFooter {
    padding: 20px;
}

.correoGodinez {
    word-wrap: break-word;
}

.instagram-bullet {
    width: 40px;
    height: 40px;
}

.redesSociales svg {
    width: 20px;
    height: 20px;
}
.instagramRed a {
    display: flex;
    color: white;
}

.navBarPair {
    display: flex;
}

.redesSociales {
    margin-top: 20px;
    margin-left: 50px;
}

.twitterRed a {
    display: flex;
    color: white;
}

.facebookRed a {
    display: flex;
    color: white;
}

@media only screen and (max-width: 991px) {
    .imageInfoFooter{
        width: 20px;
        height: 20px;
    }
    .footerContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 700px;
        background-color: #2e363a;
    }

    /* .footerContainer *{
        border: 2px red solid;
    } */

    .flexFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .textFooter {
        flex-direction: column;
        align-items: center;
        height: 700px;
    }

    .logoGM {
        width: 200px;
    }

    .hide-portal {
        display: none;
    }
}

.instagram-bullet {
    list-style: none;
}
.instagram-bullet::before {
    content: "";
    display: inline-block;
    height: y;
    width: x;
    background-image: url("https://www.svgrepo.com/show/452229/instagram-1.svg");
}

@media only screen and (max-width: 700px) {
    .logoGM {
        width: 30%;
    }

    .imageInfoFooter{
        width: 20px;
        height: 20px;
    }

    .image1footer {
        word-break: break-all;
    }

    .flexFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: fit-content;
        margin-bottom: 0;
        width: 100%;
    }

    .listfooter{
        width: 100%;
        padding-right: 0px;
        margin-top: 20px;
        padding-left: 0px;
        width: 70%;
    }

   

    .textFooter {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 700px;
        width: 100%;
    }

    .footerContainer {
        height: 800px;
    }

    .footerBottom {
        margin-top: 60px;
    }

    .liFooter {
        word-wrap: break-word;
        width: 100%;
    }
}

.containerBreadCrumbs{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 80px;
}

.containerBreadCrumbs h2{
    color: white;
    font-size: 26px;
}
.containerBreadCrumbs h4{
    font-size: 20px;
    color: white;
}
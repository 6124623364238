.topBarContainer {
    display: flex;
    justify-content: space-around;
    height: 80px;
    width: 100%;
    align-items: center;
    background-color: #001a3b;
    position: fixed;
    transform: translatey(-80px);
    z-index: 1000;
}



.topBarContainer img {
    height: 50px;
}

.topBarText{
    position: relative;
    text-decoration: none;
}


.topBarText::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffff00;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
}

.topBarText:hover::after {
    transform: scaleX(1);
  }

.topBarContainer div {
    display: flex;
}



.topBarContainer div p {
    margin-left: 20px;
    margin-right: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.topBarContainer svg {
    display: none;
}

.topBarContainer button:hover {
    background-color: #ffe545;
    color: black;
}

.burguerMenu {
    display: none;
}

.burguerMenu2 {
    display: none;
}

@media only screen and (max-width: 991px) {
    .burguerMenu {
        display: inline-block;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 50vh;
        position: fixed;
        top: 70px;
        z-index: 10;
        background-color: #00367d;
        transition: ease 1s;
    }
    .topBarContainer img {
        height: 25px;
    }

    .burguerMenu2 {
        display: flex;
        width: 100%;
        height: 0px;
        position: fixed;
        top: 70px;
        z-index: 10;
        transition: ease 1s;
    }

    .elementsBurguer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        height: 20%;
        color: #ffff;
    }

    .elementsBurguer p{
        margin-left: 15%;
    }

    .elementsBurguer2 {
        position: absolute;
        top: -1000px;
    }

    

    .topBarContainer button {
        display: none;
    }
    .topBarContainer div {
        display: none;
    }
    .topBarContainer svg {
        display: inline-block;
    }

    .topBarContainer svg:hover {
        fill: #4281d4;
    }
}

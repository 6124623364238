.contenedorContacto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: min-content;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-top: 20px;
}

.contenedorContacto .queremosSaber div h1 {
    color: #000000;
}

.marginTopContactanos h4 {
    color: #000000;
}

.contenedorContacto .formulario {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 70%;
}

.contenedorContacto .formulario .inputsContacto {
    display: flex;
    justify-content: space-between;
}

.buttonContacto {
    width: 20%;
}

.rightButton {
    display: flex;
    justify-content: flex-end;
}

.buttonContacto:active {
    background-color: yellow;
    width: 20%;
}

.queremosSaber {
    width: 100%;
    text-align: center;
    font-size: 30px;
    height: 100px;
}

.queremosSaber div {
    width: 100%;
}

.queremosSaber div h4 {
    font-size: 20px;
}

.marginTopContactanos {
    margin-top: 10px;
}

.contenedorContacto input {
    width: 30%;
}

@media only screen and (max-width: 600px) {
    .marginTopContactanos {
        margin-bottom: 20px;
    }

    .formulario {
        margin-top: 30px;
    }

    .buttonContacto {
        width: 100%;
    }

    .inputsContacto {
        flex-direction: column;
    }
    .contenedorContacto input {
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 991px) {
}

@media only screen and (min-width: 1200px) {
}

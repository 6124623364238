.contenedorNumeros {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #505050;
    
}


.titleContactanos h1{
    color: white;
}

.elementsContactanos {
    display: flex;
    justify-content: center;
}

.sectionsInfoContactanos {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    
    height: 100%;
}

.sectionsInfoContactanos p{
    font-size: 20px;
    text-align: center;
}

.sectionsInfoContactanos svg{
    width: 40%;
    height: auto;
    fill: #FFE545;
    margin-bottom: 20px;
}

.titleContactanos{
    margin-bottom: 40px;
}

.textoCorreo{
    font-size: 15px;
}



@media only screen and (max-width: 600px) {
    .titleContactanos{
        margin-top: 30px;
    }

    .sectionsInfoContactanos{
        width: 100%;
        margin-top: 30px;
    }
    .sectionsInfoContactanos p{
        font-size: 15px;
        padding: 20px;
        text-align: center;
    }
    .elementsContactanos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .contenedorNumeros {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #00367D;
        
    }
}

@media only screen and (min-width: 600px) {
    
    .sectionsInfoContactanos p{
        font-size: 15px;
        padding: 20px;
        text-align: center;
    }
    
    .sectionsInfoContactanos p{
        font-size: 15px;
        text-align: center;
        overflow-wrap: break-word; 
        
    }
}

@media only screen and (min-width: 991px) {
    .sectionsInfoContactanos p{
        font-size: 15px;
        text-align: center;
    }
}

@media only screen and (min-width: 1200px) {
    .sectionsInfoContactanos p{
        font-size: 20px;
        text-align: center;
    }
}

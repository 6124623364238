/* .Container * {
    border: 1px solid red;
} */
.Container b {
    color: #00367d;
    font-size: xx-large;
}

.Container hr {
    border-color: #00367d;
}

.topContainer {
    margin-top: 10px;
    margin-bottom: 100px;
}

.Container {
    border-radius: 20px;
    border: 10px black;
    margin-top: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 50px;
}

.Container img {
    height: 100px;
    width: auto;
}
.imageWidth {
    width: 20%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../Components/img/contaduria.jpg");
}
.imageWidth2 {
    width: 20%;
    margin-right: 20px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../Components/img/contaduria2.jpg");
}
.imageWidth3 {
    width: 20%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../Components/img/contaduria3.jpg");
}
.imageWidth4 {
    margin-right: 20px;
    width: 20%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../Components/img/contaduria4.jpg");
}
.imageWidth5 {
    width: 20%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../Components/img/contaduria5.jpg");
}

.textContainer {
    width: 40%;
    font-size: large;
    margin-right: 30px;
}

.textContainer p{
   text-align: justify;
}

@media only screen and (max-width: 991px) {
    .Container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        width: 80%;
        font-size: large;
        margin-right: 0px;
    }

    .imageWidth {
        width: 70%;
        height: 300px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../Components/img/contaduria.jpg");
    }

    .imageWidth2 {
        display: none;
    }
    .imageWidth2-2 {
        width: 70%;
        height: 300px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../Components/img/contaduria2.jpg");
    }
    .imageWidth3 {
        width: 70%;
        height: 300px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../Components/img/contaduria3.jpg");
    }
    .imageWidth4 {
        display: none;
    }

    .imageWidth4-2 {
        width: 70%;
        height: 300px;
        margin-top: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../Components/img/contaduria4.jpg");
    }
    .imageWidth5 {
        width: 70%;
        height: 300px;
        margin-top: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../Components/img/contaduria5.jpg");
    }
}

@media only screen and (min-width: 1200px) {
}

.quienesSomosContainer {
    width: 100%;
    height: min-content;
    background-color: #00367D;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding-top: 10%;
    padding-left: 10%;
    margin-top: 80px;
}

.imageContainer{
    width: 50%;
}

.textoQuienesSomos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
}

.quienesSomosContainer img{
    width: 100%;
  height: auto;
    margin-left: 50px;
}

.textoQuienesSomos{
    line-height: 20px;
}

.quienesSomosContainer h1 {
    font-size: 60px;
    line-height: 25px;
    color: white;
}

.quienesSomosContainer p {
    margin-top: 5%;
    line-height: 40px;
    font-size: 20px;
    color: white;
    padding: 10px;
    text-align: justify;
    
}

@media only screen and (max-width: 600px) {
    .quienesSomosContainer {
        height: 40vh;
        background-size: contain;
    }

    .quienesSomosContainer h1 {
        font-size: 20px;
        line-height: 20px;
        color: white;
    }
    .quienesSomosContainer p {
        margin-top: 5%;
        line-height: 10px;
        font-size: 8px;
        color: white;
    }

    .textoQuienesSomos {
        width: 40%;
    }

    .quienesSomosContainer img{
        margin-top: 30px ;
        margin-left: 10px;
    }

    .quienesSomosContainer {
        height: 250px;
    }
}

@media only screen and (min-width: 600px) {
    .quienesSomosContainer h1 {
        font-size: 30px;
        line-height: 25px;
        color: white;
    }
    .quienesSomosContainer p {
        margin-top: 5%;
        line-height: 15px;
        font-size: 15px;
        color: white;
    }
    .quienesSomosContainer {
        height: 400px;
    }
}

@media only screen and (min-width: 991px) {
    .quienesSomosContainer {
        height: 90vh;
        background-color: #00367d;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .quienesSomosContainer h1 {
        font-size: 35px;
        color: white;
    }
    .quienesSomosContainer p {
        margin-top: 5%;
        line-height: 25px;
        font-size: 20px;
        color: white;
    }
}

@media only screen and (min-width: 1200px) {
    .quienesSomosContainer {
        height: 100vh;
    }

    .quienesSomosContainer h1 {
        font-size: 65px;
        line-height: 60px;
        color: white;
    }
    .quienesSomosContainer p {
        margin-top: 5%;
        line-height: 27px;
        font-size: 20px;
        color: white;
    }
}
